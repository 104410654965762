// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-child-financial-planning-js": () => import("./../../../src/pages/services/child-financial-planning.js" /* webpackChunkName: "component---src-pages-services-child-financial-planning-js" */),
  "component---src-pages-services-estate-planning-js": () => import("./../../../src/pages/services/estate-planning.js" /* webpackChunkName: "component---src-pages-services-estate-planning-js" */),
  "component---src-pages-services-home-purchase-js": () => import("./../../../src/pages/services/home-purchase.js" /* webpackChunkName: "component---src-pages-services-home-purchase-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-institutional-investments-js": () => import("./../../../src/pages/services/institutional-investments.js" /* webpackChunkName: "component---src-pages-services-institutional-investments-js" */),
  "component---src-pages-services-insurance-protection-js": () => import("./../../../src/pages/services/insurance-protection.js" /* webpackChunkName: "component---src-pages-services-insurance-protection-js" */),
  "component---src-pages-services-investment-strategies-js": () => import("./../../../src/pages/services/investment-strategies.js" /* webpackChunkName: "component---src-pages-services-investment-strategies-js" */),
  "component---src-pages-services-minimizing-taxes-js": () => import("./../../../src/pages/services/minimizing-taxes.js" /* webpackChunkName: "component---src-pages-services-minimizing-taxes-js" */),
  "component---src-pages-services-mortgage-planning-js": () => import("./../../../src/pages/services/mortgage-planning.js" /* webpackChunkName: "component---src-pages-services-mortgage-planning-js" */),
  "component---src-pages-services-new-immigrant-settling-js": () => import("./../../../src/pages/services/new-immigrant-settling.js" /* webpackChunkName: "component---src-pages-services-new-immigrant-settling-js" */),
  "component---src-pages-services-property-tax-planning-js": () => import("./../../../src/pages/services/property-tax-planning.js" /* webpackChunkName: "component---src-pages-services-property-tax-planning-js" */),
  "component---src-pages-services-retirement-planning-js": () => import("./../../../src/pages/services/retirement-planning.js" /* webpackChunkName: "component---src-pages-services-retirement-planning-js" */),
  "component---src-pages-services-small-business-planning-js": () => import("./../../../src/pages/services/small-business-planning.js" /* webpackChunkName: "component---src-pages-services-small-business-planning-js" */)
}

